import logo from "./logo.png"

const clientData = {
    client_entity: 50,
    attorney_firm: 'Snable Stevenson & Silva',
    attorney_name: 'Josh Snable',
    attorney_number: '205 582-8000',
    attorney_email: 'joshua@snablestevenson.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_joshua_snable+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#2d363f',
    siteLink: 'http://www.snablestevenson.com/joshua-snable',
    logo
}

export default clientData